import p5 from "p5";
new p5();

const { PI, sin, atan2, round, floor, ceil, pow, max, min, random } = Math;
const SIZE = 800;
const DIM = Math.min(500, window.innerWidth, window.innerHeight);
const WIDTH = DIM;
const HEIGHT = DIM;
const RATIO = DIM / SIZE;
const RING_WIDTH = round(random() * 10 + 30);
const MIN_SEGMENT_SIZE = PI / (RING_WIDTH * 4);
const RING_SPACING = 0.5 + random() * 2;
const MAX_RINGS = (SIZE * 0.5) / (RING_WIDTH * (1 + RING_SPACING) * 2);
const NUM_RINGS = min(7, max(3, ceil(random() * MAX_RINGS)));
const DEFAULT_TIME = 1000 + round(random() * 1000);
const BASE_HUE = round(random() * 18) * 20;
const TONE_COLOR = `hsl(${BASE_HUE + 100}, 50%, 6%)`;
const SPEED = ceil(random() * 3) + 2;
document.body.style.backgroundColor = TONE_COLOR;

const easings = {
  easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - pow(-2 * x + 2, 3) / 2;
  },
  easeInOutElastic(x) {
    const c5 = (2 * PI) / 4.5;

    return x === 0
      ? 0
      : x === 1
      ? 1
      : x < 0.5
      ? -(pow(2, 20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2
      : (pow(2, -20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1;
  },
};

let defaultEasing = easings.easeInOutCubic;

function shuffle(arr) {
  let returned = [...arr];
  for (let i = returned.length - 1; i > 0; i--) {
    const j = floor(random() * (i + 1));
    [returned[i], returned[j]] = [returned[j], returned[i]];
  }
  return returned;
}

class System {
  constructor() {
    this.rings = [];
    for (let i = NUM_RINGS - 1; i >= 0; i--) {
      this.rings.push(
        new Ring(
          SIZE * 0.7 - i * RING_WIDTH * (2 + RING_SPACING),
          max(1, NUM_RINGS - i + round(random() * 4) - 2)
        )
      );
    }
  }

  draw() {
    for (let i = 0; i < this.rings.length; i++) {
      let influence = 0;
      let ring = this.rings[i];
      let pring = this.rings[i - 1];
      let nring = this.rings[i + 1];
      if (pring) influence += pring.thd.value;
      if (nring) influence += nring.thd.value;
      ring.draw(BASE_HUE, influence, true);
    }
  }
}

class Ring {
  constructor(radius, count) {
    this.th = random() * 2;
    this.thd = new MovingValue(
      (random() * 2 - 1) / (100 / SPEED),
      easings.easeInOutQuint
    );
    this.thd.set((random() * 2 - 1) / (100 / SPEED));
    this.margin = atan2(RING_WIDTH, radius) * 4;
    this.radius = radius;

    this.segments = [];
    const segs = this.getNewSegments(count);
    for (let seg of segs) this.segments.push(new MovingValue(seg));
  }
  getNewSegments(count) {
    let remaining = max(PI, PI * random() - this.margin * count);
    const returned = [];
    for (let i = 0; i < count; i++) {
      if (remaining < this.margin) remaining = this.margin;
      let num = remaining * random();
      if (num < MIN_SEGMENT_SIZE) num = MIN_SEGMENT_SIZE;
      if (num > remaining) num = remaining;
      returned.push(PI * 2 + num);
      remaining -= num;
    }
    return shuffle(returned);
  }

  draw(base, influence) {
    if (frameCount % (60 * 2.5) === 0) {
      this.thd.set((random() * 2 - 1) / (100 / SPEED));
      const segs = this.getNewSegments(this.segments.length);
      for (let [i, seg] of Object.entries(segs)) {
        this.segments[i].set(seg);
      }
    }

    const v = this.thd.value + influence;
    this.th += v;
    let th = this.th;
    for (let segment of this.segments) {
      push();

      strokeWeight(RING_WIDTH * RATIO);
      noFill();

      stroke("#00000020");
      arc(
        WIDTH / 2,
        HEIGHT / 2 + 10,
        this.radius * RATIO,
        this.radius * RATIO,
        th,
        th + segment.value
      );
      pop();
      th += segment.value;
      th += this.margin;
    }

    th = this.th;
    for (let segment of this.segments) {
      let colors = [
        {
          color: `hsla(${(base - 120) % 360},100%, 50%,1)`,
          th: th - v * 2,
        },
        {
          color: `hsla(${base % 360},100%, 50%,1)`,
          th: th,
        },
        {
          color: `hsla(${(base + 120) % 360},100%, 50%,1)`,
          th: th + v * 2,
        },
      ];

      push();
      blendMode(SCREEN);

      strokeWeight(RING_WIDTH * RATIO);
      noFill();

      for (let { color, th } of colors) {
        let startTh = th;
        stroke(color);
        arc(
          WIDTH / 2,
          HEIGHT / 2,
          this.radius * RATIO,
          this.radius * RATIO,
          startTh,
          startTh + segment.value
        );
      }

      pop();
      th += segment.value;
      th += this.margin;
    }
  }
}

const movingValues = new Set();
class MovingValue {
  constructor(initialValue, easing = defaultEasing) {
    this.easing = easing;
    this.initialValue = initialValue;
    this.value = initialValue;
    this.target = this.value;
    this.previous = this.value;
    this.previous2 = this.value;
    this.start = Date.now();
    this.time = DEFAULT_TIME / 2;
    movingValues.add(this);
  }
  remove() {
    movingValues.delete(this);
  }
  step() {
    this.previous2 = this.previous;
    this.previous = this.value;
    if (Date.now() - this.start > this.time) this.value = this.target;
    else
      this.value =
        this.initialValue +
        (this.target - this.initialValue) *
          this.easing((Date.now() - this.start) / this.time);
  }
  set(v, easing = this.easing) {
    this.easing = easing;
    this.initialValue = this.value;
    this.target = v;
    this.time = DEFAULT_TIME;
    this.start = Date.now();
  }
}

let system = new System();
window.setup = function setup() {
  createCanvas(DIM, DIM);
};

window.draw = function draw() {
  OneOfX.save();

  for (let v of movingValues) v.step();

  clear();

  system.draw();
};
